/* SCSS */
.button-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .button {
    display: block;
    position: relative;
    width: 200px;
    height: 50px;
    padding: 0;
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.primary {
      background-color: blue;
      color: white;

      &:hover {
        background-color: darkblue;
      }
    }

    &.secondary {
      background-color: gray;
      color: white;

      &:hover {
        background-color: darkgray;
      }
    }

    &.success {
      background-color: green;
      color: white;

      &:hover {
        background-color: darkgreen;
      }
    }

    &.danger {
      background-color: red;
      color: white;

      &:hover {
        background-color: darkred;
      }
    }

    &.warning {
      background-color: orange;
      color: white;

      &:hover {
        background-color: darkorange;
      }
    }
    &.loading {
      /* Escurece o botão enquanto estiver carregando */
      filter: brightness(0.75);
      pointer-events: none;
  
      .spinner {
        /* Mostra o spinner animado */
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border: 2px solid #ccc;
        border-radius: 50%;
        border-top-color: #333;
        animation: spinner 0.6s linear infinite;
      }
    }
    &.loading-1 {
      /* Altera a cor do spinner */
      border-top-color: red;
    }

    &.loading-2 {
      /* Altera a forma do spinner */
      border-left-color: green;
      border-right-color: green;
      border-top-color: transparent;
    }

    &.loading-3 {
      /* Altera a velocidade do spinner */
      animation: spinner 0.4s linear infinite;
    }

    &.loading-4 {
      /* Altera a espessura do spinner */
      
      border: 4px solid #ccc;
    }

    &.loading-5 {
      /* Altera a cor do spinner */
      border-top-color: blue;
    }
    
  }
}
