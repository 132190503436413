$primary-color: #333;
$secondary-color: #fff;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: $primary-color;
  color: $secondary-color;

  .header__logo {
    text-decoration: none;
    font-size: 22px;
    font-weight: bold;
  }

  .header__nav {
    display: flex;

    .header__nav-item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header__search-form {
    display: flex;

    .header__search-input {
      border: none;
      background-color: $secondary-color;
      color: $primary-color;
      padding: 10px;
      font-size: 16px;
    }

    .header__search-button {
      border: none;
      background-color: transparent;
      color: $primary-color;
      cursor: pointer;

      &:hover {
        color: darken($primary-color, 10%);
      }
    }
  }
}

.navbar {
  box-shadow:0px 0px 7px 3px #060606;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-around!important;
  align-items: center;
  margin-bottom: 0;
  z-index: 9999;
  background-color: #0d5962;
  li {
      padding: 10px 0px;
  }
  a {
      text-decoration: none;
      color:rgb(255, 255, 255);
  }
  a:hover {
      color: rgb(125, 255, 136);
      
  }
}


ul {
  padding-left: 0!important;
}
.carousel img {
  object-fit: cover;
  height: 88vh;
}

.bottom-bar {
  background-color: #0a4148;
  height: 30px;
}

.footer {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1em;
  background-color: #0d5962;
  color: #fff;
  .centerFooter {
      flex-direction: column;
      text-align: center;
      padding: 0 28px;
  }
  .rightFooter {
      flex-direction: column;
  }
  .separation {
      width: calc(100% / 3);
      height: 160px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  }
  @media (max-width: 430px) {
      .separation { 
          width:100%;
          height:auto;
          min-height: 100px;
      }
  }
}

.voltarMercado {
  padding-top: 3px;
}
.voltarMercado button{
  padding: 0 5px;
  border: none;
  background-color: green;
  color:#fff;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(183, 183, 183);
}
::-webkit-scrollbar-track {
  background: rgb(125, 125, 125);
}

.relative{position: relative;}
.products {
  height: auto;
}
.inputField {
  padding: 0px 0 0 20px;
}
.inputField input {
  padding: 10px;
  width: 200px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 1px solid rgb(144, 144, 144);
}
#menu button {
  padding: 10px;
  border: 1px solid rgba(144, 144, 144, 0);
  background-color: #aeaeae;
  color: #fff;
}
#menu {
  width: 89vw;
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
}
#listOfProducts {
  width: 85vw;
  height: auto;
  display: flex;
  flex-direction: start;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 30px;
  margin-bottom: 100px;
  flex-wrap: wrap;

}

#carrinho {
  position: fixed;
  right: 70px;
  margin-top: 5px;
  cursor: pointer;
  &:hover {
      transform: scale(1.1);
  }
  img {
  animation: carrinho 2s alternate ease-in-out 3;

  }
}
.menuModal {
  width: 100%;
  padding: 10px 0;
  border: 1px solid rgb(97, 97, 97);
}
.card {
  width: calc(90% / 4);
  // width: 280px;
  height: 360px;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #acacac;
  position: relative;

  @media(max-width:1060px ) {
          width: calc(90% / 3);
  }
  @media(max-width:705px ) {
      width: 46%;
  }
  @media(max-width:440px ) {
      width: 96%;
  }
  @media(min-width:1750px ) {
      width: calc(90% / 5)
  }
  .imgProduct {
      width: 100%;
      height: 200px;
      padding: 10px;
      border-radius: 20px;
      object-fit: cover;
  }
  .title {
      padding: 10px;
      font-weight: 600;
  }
  .description {
      padding: 0 10px;
      font-size: 13px;
      line-height: 1.35;
      overflow: hidden; // Removendo barra de rolagem
      text-overflow: ellipsis; // Adicionando "..." ao final
      display: -webkit-box;
      -webkit-line-clamp: 4; // Quantidade de linhas
      -webkit-box-orient: vertical; 
  }
  .carrinho {
      border: 1px solid #dadada;
      text-align: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  }
  .carrinho:hover {
      background-color: rgb(229, 229, 229);
      cursor: pointer;
  }
  .carrinho img {
      width: 32px;
  }
}

#count {
  width: 85vw;
  margin: 0px auto 17px auto;
}

.checkout {
  padding: 5px;
  right: 0;
  top: 150px;
  box-shadow: -3px 0px 5px 2px #696969a3;
  width: 550px;
  height: 85vh;
  z-index:9999;
  background-color:#fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  // display: none;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal {
  display: none;
  position: fixed;
  right: 0;
  top: 10%;
}

.closeCheckout {
  position: absolute;
  display: flex;
  width: calc(100% / 3);
  width: 100%;
  text-align: center;
  background-color: #025d54;
  color: #fff;
  border-bottom-left-radius: 10px;
  z-index: 99999;
  cursor: pointer;
  &:hover {
      background-color:rgb(21, 100, 72)
  }
}
.showAnimation {
  animation: showCheckout .3s alternate ease-in-out;
}

.closeAnimation {
  animation: closeCheckout .3s alternate ease-in-out;
}
.fundoPreto {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.363);
  z-index: 999;
}

@keyframes showCheckout {
  0%{margin-right: -513px;}
  100%{margin-right: 0;}
}

@keyframes closeCheckout {
  0%{margin-right: 0;}
  100%{margin-right: -513px;}
}
@keyframes carrinho {
  0%{transform: scale(1.05);}
  25%{transform: scale(0.90);}
  50%{transform: scale(1.05);}
  75%{transform: scale(0.90);}
  100%{margin-right: 1}
}

.boxProduct {
  width: 98%;
  height: 120px;
  display: flex;
  padding: 5px;
  border: 1px solid #039789;;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: -2px 0px 6px 1px #7c7c7c;
  position: relative;
  margin-top: 7px;
}
.boxProduct img {
  width: 30%;
  height: auto;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.price {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  width: 20%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #039789;
  color: #fff;
}
.descriptionCheckout {
  padding: 5px;
  width: 50%;
  overflow-y: auto;
  line-height: 1.4;
  h3 {
      font-weight: bolder;
  }
  p {
      font-size: 0.8em;
  }
}

.vazioCarrinho {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: #c9c9c9;
}

.sku {
  display: flex;
  align-items: center;
}