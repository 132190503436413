
.tic-tac-toe {
    background-color: rgba(0, 0, 0, 0.678);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
        color: rgb(0, 170, 255);
        font-size: 35px;
        margin-bottom: 0;
        font-weight: bolder;
    }
    .status {
        color: rgb(0, 170, 255);
        text-align: center;
        width: 75vw;
        font-size: 20px;
        padding: 20px 0;
    }
    .board {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        width: 75vw;
      
        .square {
            height: 25vh;
            font-weight: bolder;
            font-size: 100px;
            font-family: cursive;
        }
    }
    .reset-button {
        width: 150px;
        margin: 0 0 10px 0;
        padding: 10px 15px;
        background-color: #2494c1;
        color: #fff;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0px 6px 0px #383838;
    }
    @media (max-width: 768px) {
        .board {
            .square {
                height: 18vh;
            }
        }
    }
}