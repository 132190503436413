html {
  scroll-behavior: smooth;

}
body {
  margin: 0;
  font-family: 'system-ui', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  cursor: url('./assets/img/cursor.cur'), pointer;
}

img,
link,
a,
input,
button,
textarea {
  cursor: url('./assets/img/mao.cur'), pointer;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: rgb(0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #344da9;
}

