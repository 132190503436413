
.background {
    width: 90vw;
    margin: 0 auto 50px auto;
    background-color: #2e2e2e;
}

.ml-1 {margin-left: 10px;}
.ml-2 {margin-left: 20px;}
.ml-3 {margin-left: 30px;}
.ml-4 {margin-left: 40px;}
.ml-5 {margin-left: 50px;}

.disabled {
    opacity: 0.5;
    cursor: default;
}

a {
    color: #fff;
    text-decoration: none;
}
main {
  background-color: #31186a;

}
.loading {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.501);
    width: 100%;
    height: 100vh;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
        border: 10px solid #00fffd;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border-left-color: #008189;
        animation:  rotationInfinity 1s linear infinite;
    }
    
}
.title {
    width: 90vw;
    margin: 0 auto 0 auto;
    border: none;
    padding: 1px;
    margin-top: 100px;
    color: #fff;
    position: relative;

    h3 {
        padding: 10px;
        border: none;
        width: max-content;
        margin-bottom: -1px;
        border-radius: 1px;
        background-color: #00000050;
        letter-spacing: 2px;
    }
}
// .container-top {
//     .navbar {
//         position: absolute;
//         color: #fff;
//         width: 100%; /* Melhor fallback */
//         background-color: rgba(68, 68, 68, 0.26);
        
//         a {
//             color: #fff;
//             text-decoration: none;
//         }
//         ul {
//             display: flex;
//             justify-content: flex-end; /* Alinhamento correto */
//             list-style: none;
//             align-items: center;
//         }
//         li {
//             margin-right: 3rem;
//             color: #00e7ff;
//             border: 1px solid rgba(46, 138, 136, 0.2);
//             padding: 0.625rem 1.25rem;
//             background-color: rgba(0, 0, 0, 0.28);
//             border-radius: 0.125rem;
//             transition: all 0.2s ease-in-out;
//             width: 4.6875rem; /* Ajustado */
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             &:hover, &:focus {
//                 cursor: pointer;
//                 box-shadow: 0px 2px 4px -5px #ffffff;
//                 transform: translateY(-2px);
//                 background-color: #000;
//             }
//         }
//     }

//     .box-astronauta {
//         position: relative;
//         .text-astronauta {
//             position: absolute;
//             right: 12.5rem;
//             top: 14.375rem;
//             color: #000;
//             border-radius: 2px;
//             font-weight: bolder;
//             border: 1px solid;
//             padding: 10px;
//             background-color: #fff;
//             animation: animaOpacityText 2s alternate ease-in-out;
//             &::after {
//                 content: '';
//                 position: absolute;
//                 right: -5px;
//                 bottom: -1px;
//                 width: 25px;
//                 height: 12px;
//                 background-color: white;
//                 border-bottom-left-radius: 23px;
//                 transform: rotate(187deg);
//             }
//         }
//         .astronauta {
//             z-index: 999;
//             width: 12.5rem;
//             position: absolute;
//             right: 0;
//             top: 15.625rem;
//             animation: animaRightToLeft 2s alternate ease-in-out;
//         }
//     }
    
//     .caixa-foguete {
//         position: absolute;
//         left: 50%;
//         top: 80%;
//         transform: translate(-50%, -50%);
//         .foguete {
//             width: 6.25rem;
//             height: 10.3125rem;
//             position: relative;
//             border: 1px solid transparent;
//             z-index: 99;
//             animation: animaFoguete 8s alternate ease-in-out;
//             .carcaca {
//                 width: 100%;
//                 object-fit: cover;
//                 position: absolute;
//                 top: 0;
//                 z-index: 99;
//             }
//             .fogo {
//                 width: 100%;
//                 transform: rotate(180deg);
//                 margin-bottom: 3.125rem;
//                 position: absolute;
//                 bottom: -9.0625rem;
//                 animation: animaOpacity 8s alternate ease-in-out;
//             }
//         }
//     }
// }
.container-top {
    background-image: url('./assets/img/modelo-5.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
    box-shadow: 0px 0px 66px 31px #46199f;

    .navbar {
        position: absolute;
        color: #fff;
        // width: 100%;
        width: -webkit-fill-available;
        background-color: #44444442;
        a {
            color: #fff;
            text-decoration: none;
        }
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            text-align: center;
            align-items: center;
        }
        li {
            margin-right: 50px;
            color: #00e7ff;
            border: 1px solid #2e8a8833;
            padding: 10px 20px;
            background-color: #00000047;
            border-radius: 2px;
            transition: all .2s;
            width: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover,
            &:focus {
                cursor: pointer;
                box-shadow: 0px 2px 4px -5px #ffffff;
                transform: translateY(-2px);
                background-color: #000;
            }
        }
    }
    .box-astronauta {
        position: relative;
        .text-astronauta {
            position: absolute;
            right: 200px;
            top: 230px;
            color: #000;
            border-radius: 2px;
            font-weight: bolder;
            border: 1px solid;
            padding: 10px;
            background-color: #fff;
            animation: animaOpacityText 2s alternate ease-in-out;
            &::after {
                content: '';
                position: absolute;
                right: -5px;
                bottom: -1px;
                width: 25px;
                height: 12px;
                background-color: white;
                border-bottom-left-radius: 23px;
                border-top-right-radius: -11px;
                transform: rotate(187deg);
            }
        }
        .astronauta {
            z-index: 999;
            width: 200px;
            position: absolute;
            right: 0;
            top: 250px;
            animation: animaRightToLeft 2s alternate ease-in-out;
        }
    }
    
    .caixa-foguete {
        position: absolute;
        left: 50%;
        top: 690px;
        transform: translate(-50%, -50%);
        .foguete {
            width: 100px;
            height: 165px;
            position: relative;
            border: 1px solid transparent;
            z-index: 99;
            animation: animaFoguete 8s alternate ease-in-out;
            .carcaca {
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                z-index: 99;
            }
            .fogo {
                width: 100%;
                transform: rotate(180deg);
                margin-bottom: 50px;
                position: absolute;
                bottom: -145px;
                animation: animaOpacity 8s alternate ease-in-out;
            }
        }
    }
}


.container {
    width: 90vw;
    margin: 0 auto;
    background-color: #00000050;
    color: #fff;
    box-shadow: 0px 5px 2px 0px #0b0b0b8c;
}
.name-skills {
    display: flex;
    height: 500px;
    .box-buttons {
        margin: 0;
        width: 150px;
        height: 100%;
        list-style: none;
        padding: 0;
        .buttonActive {
            border: none;
            text-align:center;
            padding: 20px 0px;
            height: 25%;
            width: 100%;
            color: #fff;
            border: 1px solid #56565600;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #00000000;
            transition: all .2s;
            cursor: pointer;
            &:hover {
                transition: all .2s;
            }
        }
        .buttonInative {
            text-align:center;
            padding: 20px 0px;
            height: 25%;
            width: 100%;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 1px solid #3f3f3f45;
            align-items: center;
            background-color: #00000050;
            transition: all .2s;
            &:hover {
                cursor: pointer;
                transition: all .2s;
            }
        }
    }
    .container-skills {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow-y: auto;
        overflow-x: hidden;
        gap: 10px;
        padding: 20px;
        width: 100%;
        grid-auto-rows: max-content;
        .box-skill {
            border: 1px solid #484848;
            display: flex;
            padding: 10px 20px;
            background-color: #000000;
            height: max-content;
            animation: showSkill .5s alternate ease-in-out;
            position: relative;
            .seloSkill {
                position: absolute;
                right: 10px;
                top: 15px;
                img {
                    width: 28px;
                }
            }
            img {
                width: 50px;
            }
            p {
                margin: 0 20px 0 20px;
                white-space: nowrap;
                font-weight: 700;
                color: #b5b5b5;
            }
            .size-Progressbar {
                width: 70%;
            }
            .progressBar {
                border: 1px solid #4dff00;
                font-weight: bolder;
                padding: 2px;
                margin: 18px 10px 0 20px;
                height: 5px;
                border-radius: 20px;
            }
        }
    }
}

.projects-box {
    .project-box {
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        .box-project {
            border: 1px solid #000;
            border-radius: 8px;
            padding: 20px;
            background-color: #00000050;
            transition: all .2s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            &:hover {
                cursor: pointer;
                background-color: #1d5a5e;
                transition: all .2s;
            }
            img {
                width: 40%;
            }
            p {
                color: #fff;
                text-align: center;
                font-weight: 600;
            }
            .box-elements-projects {
                padding: 10px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
}


.box-aboutMe {
    position: relative;
    background-color: #31186a;
    box-shadow: none;
    border: 1px;

    margin-top: 150px;
    box-shadow: 0px 5px 2px 0px #0b0b0b8c;
    .aboutMe-box {
        display: flex;
        .aboutMe-photo {
            img {
                width: 100%;
                animation:  delayImagePerfil 3s alternate ease-in-out;
            }
        }
        .description {
            padding: 20px;
            width: 75vw;
            font-size: 18px;
            line-height: 29px;
            color: #afafaf;
            font-weight: 600;
            background-color: #00000050;
            h3 {
                margin-bottom: 30px;
            }
        }
        .jsonMe {
            background-color: #00000087;
            color: #14d114;
            width: max-content;
            // max-width: 25vw;
            // height: 95%;
            padding: 28px;
            font-size: 15px;
            font-weight: bolder;
            line-height: 1.5;
            /* border: 1px solid #09140a; */
            border-radius: 5px;
            box-shadow: -5px 0px 0px 0px #2abf36;
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            justify-content: center;
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
            .number {
                color: #a900ff;
            }
            .value {
                color: #cdcd16;
            }
        }
    }
    .alienigena {
        position: relative;
        top: 20px;
        right: 10px;
        width: 34vw;
        position: absolute;
        img {
            animation: naveEspacialAnima 30s linear infinite;
            width: 50px;
        }
    }
    @media (max-width:600px) {
        .aboutMe-box {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

    }
}


.box-contact-me {
    display: flex;
    height: 300px;
    .socialMedia {
        padding: 15px;
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
      
        .box-socialMedia {
            filter: opacity(0.5);
            max-width: 200px;
            display: flex;
            margin: 15px auto 10px auto;
            // width: 212px;
            padding: 9px 20px;
            align-items: center;
            background-color: #00000082;
            box-shadow: 0px 0px 3px 1px #000000;
            transition: all .2s;
            &:hover {
                transition: all .2s;
                // background-color: #2a8086;
                cursor: pointer;
                filter: opacity(1);
            }
        }
        span {
            font-weight: 600;
            letter-spacing: 2px;
        }
        img {
            width: 50px;
            height: 50px;
            margin-right: 20px;

        }
    }

    form {
        width: 40vw;
        display: flex;
        flex-direction: column;
        padding: 15px;
        button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: #286867;
            color: #fff;
            float: right;
            box-shadow: 0px 0px 8px 0px black;
            margin-top: 15px;
            font-weight: 600;
            transition: all 0.2s;
            &:hover {
                transition: all 0.2s;
                background-color: #2e8a88;
                cursor: pointer;
            }
        }
        textarea {
         padding-left: 10px;
         height: 100px;
         resize: none;
         background-color: #0000004a;
         border: 1px solid #060606;
         color: #fff;
        }
        input {
         height: 40px;
         padding-left: 10px;
         background-color: #0000004a;
         border: 1px solid #060606;
         color: #fff;
         &:nth-child(n - 2){
            margin-bottom: 10px;
        }
         
        }
    }
}

.topPage {
    z-index: 99999;
    position: fixed;
    bottom: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #000000a1;
    color: #686868;
    box-shadow: 0px 0px 4px 1px #2e2e2e;
    font-size: xx-large;
    font-family: cursive;
    animation: animaOpacityText 1s alternate ease-in-out;
    &:hover{
        cursor: pointer;
        color: #fff;
    }
}

.copy-right {
    width: 100%;
    text-align: center;
    margin-top: 100px;
    background-color: #00000050;
    box-shadow: 0px 0px 6px 0px #080808;
    color: #fff;
    padding: 15px 0;
    p {
        margin: 0;
    }
}




@keyframes rotationInfinity {
    from{
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes naveEspacialAnima {
    0%{margin-left: 10px;}
    50%{margin-left:  27vw;}
    100%{margin-left:  10px;}
}
@keyframes animaOpacity {
    0%{opacity: 1;}
    50%{opacity: 1;}
    51%{opacity: 0;}
    85%{opacity: 1;}
    86%{opacity: 0;}
    95%{ opacity: 1;}
    96%{ opacity: 0;}
    100%{ opacity: 0;}
}
@keyframes animaFoguete {
    0%{transform: rotate(90deg) scale(0.4); right: 70vw; top: -200px; }
    45%{transform: rotate(90deg) scale(0.4); right: -110vw; top: -200px;}
    60%{transform: rotate(10deg) scale(1); top: -100vh; margin-left: 150vw;}
    71%{transform: rotate(10deg) scale(1); top: -100vh; margin-left: 50vw;;}
    82%{transform: rotate(35deg) scale(1);}
    94%{transform: rotate(0deg);margin-left:0;}
    95%{transform: rotate(0deg);margin-left:0;}
    100%{transform: rotate(0deg); top: 0; }
}

@keyframes animaOpacityText {
    0%{opacity: 0;}
    80%{opacity: 0;}
    100%{ opacity: 1;}
}
@keyframes animaRightToLeft {
    0%{right: -200px;}
    100%{right: 0;}
}

@keyframes showSkill {
    0% {
        opacity: 0;
        transform: translate(-40px, 0);
      }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

@media (max-width: 1242px) {
    .name-skills {
        .container-skills {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .projects-box {
        .project-box {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
@media (max-width: 950px) {
    .name-skills {
        .container-skills {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .projects-box {
        .project-box {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media (max-width: 740px) {
    .name-skills {
        .box-buttons {
            width: 71px;
        }
        .container-skills {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .projects-box {
        .project-box {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
@media (max-width: 600px) {

    
.box-aboutMe {
  
    .aboutMe-box {
        display: flex;
        .aboutMe-photo {
            padding: 20px;
            img {
                width: 100%;
                animation:  delayImagePerfil 3s alternate ease-in-out;
            }
        }
        .description {
            padding: 20px;
            width: 75vw;
            font-size: 18px;
            line-height: 29px;
            color: #afafaf;
            font-weight: 600;
            background-color: #00000050;
            h3 {
                margin-bottom: 30px;
            }
        }
        .jsonMe {
         
            width: max-content;
            max-width: 80vw;
            text-align: left;

        }
    }
}

    .container-top {
        .navbar{
            ul{
                justify-content: space-around;
                padding: 0 0px 25px 0;
            }
            li {
                margin-right: 0px;
            }
        }
    }
    .box-contact-me {
        display: flex;
        height: auto;
        flex-direction: column;
        .socialMedia {
            padding: 15px;
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
          
            .box-socialMedia {
                filter: opacity(0.5);
                max-width: 100%;
    
            }
        }
    
        form {
            width: 92%;
            flex-direction: column;
            button {
                width: 150px;
                padding: 10px;
            }
        }
    }
    
}
@media (min-width: 1350px) {
    .projects-box {
        .project-box {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}
@media (min-width: 1650px) {
   
    .projects-box {
        .project-box {
            grid-template-columns: repeat(6, 1fr);
        }
    }
}




